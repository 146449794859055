import React from "react";
import { container, zeroPadding, narrowWidth, veryNarrowWidth, oneColumnWidth } from "./container.module.scss";

const Container = ({ noPadding, children, neutralPageWidth, mdxPageWidth, oneColumn }) => {
  return (
    <div
      className={`${container} ${noPadding ? zeroPadding : ""} ${
        neutralPageWidth ? narrowWidth : ""
      } ${mdxPageWidth ? veryNarrowWidth : ""} ${oneColumn ? oneColumnWidth : ""}`}
    >
      {children}
    </div>
  );
};

export default Container;
